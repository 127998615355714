<template>
<div class="tgju-widget last-post light">
    <div class="list-widget  list-row bold list-row-title border-0">
        <div class="list-col l-title dot head-color no-border">
            <span>آخرین تحلیل‌های دنبال‌شوندگان</span>
        </div>
        <div class="list-col l-item  head-color">
            <i class="uil uil-chart"></i>
        </div>
    </div>
    <div class="tgju-widget-content full-padding">
        <div v-if="widgetLoad" class="tgju-widget-content full-padding">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
        <slot v-else-if="posts.length">
            <div v-for="post in posts" :key="post.id" class="list-row d-block hover">
                <!-- <router-link v-if="isMyPost(post.user.username)" :to="{ name: 'Profile' }" class="link-title">
                    <div v-html="post.content"></div>
                    <img class="last-post-image-size" :src="$helpers.api_url() + post.attachments" />
                    <h3 class="last-post-title-size">{{post.title}}</h3>
                </router-link> -->
                <router-link :to="{ name: 'ViewTechnical' , params: {id: post.id}}" class="link-title">
                    <div v-html="post.content"></div>
                    <img class="last-post-image-size" :src="$helpers.api_url() + post.attachments" />
                    <h3 class="last-post-title-size">{{post.title}}</h3>
                </router-link>
                <div class="link-items">
                    <i class="uil uil-user link-item-icon"></i>
                    <span v-tooltip="'کاربر'" @click.prevent="getRoute('profile')" class="badge badge-primary"> {{ post.user ? post.user.username : post.name}}</span>
                    <span class="time">
                        <div v-tooltip="'تاریخ ارسال'">{{ toRelative(post.created_at) }}</div>
                    </span>
                </div>
            </div>
        </slot>
        <div v-else class="text-center p-2">
            <div class="not-found-items">
                <i class="uil uil-exclamation"></i>
                مقداری یافت نشد.
            </div>
        </div>
    </div>
</div>
</template>

<script>
// این کامپونتت برای نمایش لیست  نظرات ارسالی دوستان کاربر می باشد
import moment from "moment-jalaali";
export default {
    name: "LastFollowingTechnicals",
    components: {},
    props: ['username'],
    data: function () {
        return {
            posts: {
                id: undefined,
                length: undefined,
                created_at: undefined,
                user: {
                    username: undefined,
                },
            },
            widgetLoad: true,
        };
    },
    mounted() {
        // در خواست دریافت اطلاعات از سرور
        this.getLastPost();
    },
    methods: {
        toRelative(date) {
            return moment(date).fromNow();
        },
        // وظیفه این متد دریافت اطلاعات از سرور می باشد
        getLastPost() {
            //ارسال درخواست ای پی آی
            this.$helpers.makeRequest("GET", "/tgju-post/last-following-technicals", {
                    'username': this.username
                })
                .then((api_response) => {
                    if (api_response.data.response.status == "success") {
                        this.posts = api_response.data.response.detail;
                        this.widgetLoad = false;
                    }
                });
        },
        // وضعیت پست های مربوط به پروفایل خودی را برمی گرداند
        isMyPost(data) {
            return this.$store.state.user.username == data;
        },

        getRoute(route){
            this.$router.push({ name: route });
        }
    },
};
</script>
